<template>
  <div
    class="product-overview"
    id="overview"
  >
    <v-subtitle
      zh="产品概述"
      en="Product overview"
      type="mini"
    />
    <div class="product-main">
      <div class="l">
        <div class="title">
          {{ data.title }}
        </div>
        <div class="content">
          <div
            class="text"
            v-for="(item,index) in data.content"
            :key="index"
          >
            <p>
              {{ item }}
            </p>
          </div>
        </div>
      </div>
      <div class="r">
        <img :src="data.img">
      </div>
    </div>
  </div>
</template>

<script>
import vSubtitle from '@/components/subtitle/index.vue'
export default {
  components: {
    vSubtitle
  },
  props: {
    data: {
      type: Object,
      default: () => {

      }
    }
  }
}
</script>

<style lang="less" scoped>
.product-overview {
  padding-top: 50px;
  margin-bottom: 77px;
  .product-main {
    display: flex;
    justify-content: space-between;
    margin-top: 78px;
    .l {
      margin-right: 92px;
      flex: 1;
      .title {
        background: linear-gradient(180deg, #f3f5f8 0%, #fefefe 100%);
        border: 3px solid #ffffff;
        font-size: 20px;
        font-family: Microsoft YaHei-Regular, Microsoft YaHei;
        font-weight: 400;
        color: #000000;
        line-height: 26px;
        padding: 33px 106px 32px 65px;
        box-sizing: border-box;
        display: inline-block;
        position: relative;
        &::before {
          content: '';
          display: inline-block;
          width: 5px;
          height: 45px;
          background: linear-gradient(180deg, #378ff6 0%, #0b64fb 100%);
          position: absolute;
          left: 0;
          top: 50%;
          transform: translateY(-50%);
        }
      }
      .content {
        margin-top: 47px;
        .text {
          display: flex;
          align-items: flex-start;
          &::before {
            content: '';
            display: inline-block;
            border: 6px solid transparent;
            border-left-color: #000000;
            opacity: 0.54;
            margin-right: 12px;
            margin-top: 4px;
          }
          p {
            font-size: 14px;
            font-family: Microsoft YaHei-Light, Microsoft YaHei;
            font-weight: 300;
            color: #0f0f0f;
            line-height: 20px;
            margin-bottom: 15px;
          }
        }
      }
    }
    .r {
      border-radius: 6px;
      width: 519px;
      height: 295px;
      overflow: hidden;
      box-shadow: @gBoxShadow;
      img {
        height: 100%;
        width: 100%;
      }
    }
  }
}
</style>
