<template>
  <div class="industry-swiper">
    <v-subtitle
      zh="行业痛点"
      en="Application scenarios"
      type="mini"
    />
    <div class="swiper">
      <swiper
        :options="swiperOptions"
        v-if="!loading"
      >
        <swiper-slide
          v-for="(i, index) in 3"
          :key="index"
        >
          <v-scenarios :data="scenariosData" />
        </swiper-slide>
      </swiper>
      <!-- 条 -->
      <div class="swiper-pagination" />
      <div class="swiper-prev" />
      <div class="swiper-next" />
    </div>
  </div>
</template>

<script>
import vSubtitle from '@/components/subtitle/index.vue'
import vScenarios from './scenarios.vue'
export default {
  components: {
    vSubtitle,
    vScenarios
  },
  props: {
    item: {
      type: Object,
      default: () => ({
        id: '',
        img: '',
        title: '',
        content: ''
      })
    },
    interval: {
      type: Number,
      default: 5000
    }
  },
  data () {
    return {
      scenariosData: {
        id: '1',
        img: '/static/img/productCenter/c-2.png',
        title: '公共资源交易中心强化公共服务定位的需要',
        content:
          '《关于深化公共资源交易平台整合共享的指导意见》明确“公共资源交易中心作为公共资源交易平台主要运行服务机构，应不断优化见证、场所、信息、档案、专家抽取等服务。”交易见证作为公共资源交易中心的一项重要服务内容，就是要详细记录项目交易全过程信息妥善保存，并提供便捷的查证服务，实现项目交易全程可溯可查。'
      },
      loading: false,
      // #region 轮播图配置
      swiperOptions: {
        autoplay: {
          delay: this.interval
        },
        speed: 1000,
        loop: true,
        pagination: {
          el: '.swiper-pagination',
          clickable: true
        },
        navigation: {
          nextEl: '.swiper-next',
          prevEl: '.swiper-prev'
        }
      }
    }
  },
  methods: {}
}
</script>

<style lang="less" scoped>
.industry-swiper {
  padding-top: 50px;
  padding-bottom: 50px;
  width: 100%;
  .swiper{
    position: relative;
    width: 100%;
    &-next,
    &-prev{
        width: 68px;
        height: 68px;
        position: absolute;
        top: 50%;
        transform: translateY(-50%);
        cursor: pointer;
        z-index: 8;
    }
    &-next{
        right: 0;
        background: url("/static/img/productCenter/next.png");
    }
    &-prev{
        left: 0;
        background: url("/static/img/productCenter/prev.png");
    }
  }
  /deep/ .swiper {
    height: 100%;
    .swiper-pagination {
      bottom: -22px;
      left: 50%;
      transform: translateX(-50%);
      width: auto;
      z-index: 8;
    }
    .swiper-pagination-bullet {
      border-radius: 0;
      height: 6px;
      width: 6px;
      background: #333;
      opacity: .22;
      cursor: pointer;
      &:not(:last-child) {
        margin-right: 8px;
      }
    }
    .swiper-pagination-bullet-active {
      background: #333;
      opacity: 1;
    }
  }
}
</style>
