<template>
  <div class="service-system">
    <v-banner-box :data="swiperData" />
    <v-nav-bar />
    <v-product-overview :data="overviewData" />
    <div
      class="banner100vw banner100vw-linear-gradient"
      id="scenarios"
    >
      <v-industry-swiper />
    </div>
    <v-industry :pain-points="painPoints" />
    <div
      class="banner100vw banner100vw-20p30t banner100vw-linear-gradient"
      id="frame"
    >
      <v-frame :frameimg="frameImg" />
    </div>
    <v-technology :technology="technology" />
    <div
      class="
        banner100vw banner100vw-20p30t banner100vw-linear-gradient
        productadvantage-bg
      "
      id="advantage"
    >
      <v-productadvantage :typeslist1="characteristic" />
    </div>
    <v-app-stories :case-list="caseList" />
  </div>
</template>

<script>
// NOTE :  产品中心
import vBannerBox from '@/components/banner/bannerBox.vue'
import vNavBar from './components/navBar/index.vue'
import vProductOverview from './components/productOverview/index.vue'
// import vAppScenarios from './components/appScenarios/index.vue'
import vIndustrySwiper from './components/industrySwiper/index.vue'
import vIndustry from './components/industry/index.vue'
import vFrame from './components/frame/index.vue'
import vTechnology from './components/technology/index.vue'
import vProductadvantage from './components/productadvantage/index.vue'
import vAppStories from './components/appStories/index.vue'
export default {
  components: {
    vBannerBox,
    vNavBar,
    vProductOverview,
    vIndustrySwiper,
    vIndustry,
    vFrame,
    vTechnology,
    vProductadvantage,
    vAppStories
  },
  data () {
    return {
      swiperData: [],
      overviewData: {
        title: '公共资源交易智能见证系统',
        content: [
          '电子档案必须具有独立性，因此电子档案系统必须是独立于电子服务系统和电子交易系统独立存在的，同样电子档案数据也必须是不依赖任何系统独立存在的。',
          '文锐电子档案系统是完全独立的系统，数据归档后自动生成每个项目的档案文件（PDF和XML文件），电子服务系统和电子交易系统的运行状况是否正常、是否更换系统、甚至系统是否存在，均不会影响电子档案的管理和利用。'
        ],
        img: '/static/img/productCenter/intelligentSystem.png'
      },
      painPoints: [
        {
          src: '/static/img/productCenter/a-1.png',
          content:
            '当招投标过程中出现质疑投诉时，监管人员无法了解项目实际的执行过程；'
        },
        {
          src: '/static/img/productCenter/a-2.png',
          content: '项目档案容易被篡改，不能作为调查依据。'
        }
      ],
      frameImg: '/static/img/productCenter/a-5.png',
      technology: '/static/img/productCenter/a-6.png',
      characteristic: [
        {
          img: '/static/img/productCenter/b-4.png',
          title: '智能化',
          content:
            '视频识别、语音识别分析技术，自动核验身份、自动记录分析异常行为和谈话内容，异常行为图像和音频自动报警并截取存证。'
        },
        {
          img: '/static/img/productCenter/b-5.png',
          title: '区块链',
          content:
            '提供交易关键逻辑的区块链智能合约，确保交易中心见证电子交易系统的关键程序逻辑公开透明、执行过程公开、数据全面上链存证，保证系统运行过程和结果不受人为因素干预。'
        },
        {
          img: '/static/img/productCenter/b-6.png',
          title: '区块链查证',
          content:
            '提供基于区块链浏览器的见证数据查证渠道；将见证数据应用于公共资源交易大数据分析，并整合到电子档案系统存档。'
        }
      ],
      caseList: []
    }
  },
  methods: {
    freeTrial () {
      console.log('免费试用')
    },
    /**
     * 获取轮播图
     */
    getSwiperData () {
      this.loading = true
      const params = {
        parentId: this.$siteTypes.bannerTypes['公共资源交易智能见证系统']
      }
      this.swiperData = []
      this.$api
        .getBannerInfo(params)
        .then(({ data: res }) => {
          this.loading = false
          if (res.code !== 0) {
            return console.error(res)
          }
          this.swiperData = res.data
        })
        .catch((err) => {
          this.loading = false
          console.error(err)
        })
    },
    // 获取案例 - 公共资源交易智能见证系统
    getCaseList () {
      this.loading = true
      const params = {
        parentId: this.$siteTypes.caseTypes['公共资源交易智能见证系统']
      }
      this.$api
        .getCaseInfo(params)
        .then(({ data: res }) => {
          this.loading = false
          if (res.code !== 0) {
            return console.error(res)
          }
          this.caseList = res.data
        })
        .catch((err) => {
          this.loading = false
          console.error(err)
        })
    }
  },
  mounted () {
    this.getSwiperData()
    this.getCaseList()
  }
}
</script>

<style lang="less" scoped>
.service-system {
  .banner100vw();
  .productadvantage-bg {
    &::before {
      background: url('/static/img/theSolution/a-1.png') no-repeat center center /
          100% auto,
        linear-gradient(180deg, #f3f5f8 0%, #fefefe 100%);
    }
  }
}
</style>
